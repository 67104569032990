var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"axil-shop-area axil-section-gap bg-color-white"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('FilterSidebar',{attrs:{"filter":_vm.filter,"rarities":_vm.rarities,"legalities":_vm.legalities,"colors":_vm.colors}})],1),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"axil-shop-top mb--40"},[_c('div',{staticClass:"category-select align-items-center justify-content-lg-end justify-content-between"},[(_vm.items && _vm.items.meta)?_c('span',{staticClass:"filter-results"},[_vm._v("Találatok száma: "+_vm._s(_vm.items.meta.total)+" db")]):_vm._e(),_c('b-form-select',{staticClass:"single-select",attrs:{"options":{
                                        null: 'Sorszám szerint növekvő',
                                        reverse: 'Sorszám szerint csökkenő',
                                        price_asc: 'Ár szerint növekvő',
                                        price_desc: 'Ár szerint csökkenő',
                                        quantity_asc:
                                            'Mennyiség szerint növekvő',
                                        quantity_desc:
                                            'Mennyiség szerint csökkenő'
                                    }},on:{"change":_vm.setSortBy},model:{value:(_vm.filter.sortBy),callback:function ($$v) {_vm.$set(_vm.filter, "sortBy", $$v)},expression:"filter.sortBy"}})],1),_c('div',{staticClass:"d-lg-none"},[_c('button',{staticClass:"product-filter-mobile filter-toggle"},[_c('i',{staticClass:"fas fa-filter"}),_vm._v(" "+_vm._s(_vm.$t("SZŰRŐ"))+" ")])])])])]),_c('div',{staticClass:"row row--15"},_vm._l((_vm.items.data),function(item,index){return _c('ProductRow',{key:'product-' + index,attrs:{"product":item}})}),1),_c('div',{staticClass:"text-center pt--20"},[(_vm.showLoadMore)?_c('a',{staticClass:"axil-btn btn-bg-lighter btn-load-more",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.loadMoreData($event)}}},[_vm._v(_vm._s(_vm.$t("Továbbiak betöltése")))]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }