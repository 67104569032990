<template>
    <div class="axil-shop-area axil-section-gap bg-color-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <FilterSidebar
                        :filter="filter"
                        :rarities="rarities"
                        :legalities="legalities"
                        :colors="colors"
                    />
                    <!-- End .axil-shop-sidebar -->
                </div>
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="axil-shop-top mb--40">
                                <div
                                    class="category-select align-items-center justify-content-lg-end justify-content-between"
                                >
                                    <!-- Start Single Select  -->
                                    <span
                                        class="filter-results"
                                        v-if="items && items.meta"
                                        >Találatok száma:
                                        {{ items.meta.total }} db</span
                                    >
                                    <b-form-select
                                        v-on:change="setSortBy"
                                        v-model="filter.sortBy"
                                        class="single-select"
                                        :options="{
                                            null: 'Sorszám szerint növekvő',
                                            reverse: 'Sorszám szerint csökkenő',
                                            price_asc: 'Ár szerint növekvő',
                                            price_desc: 'Ár szerint csökkenő',
                                            quantity_asc:
                                                'Mennyiség szerint növekvő',
                                            quantity_desc:
                                                'Mennyiség szerint csökkenő'
                                        }"
                                    ></b-form-select>
                                    <!-- End Single Select  -->
                                </div>
                                <div class="d-lg-none">
                                    <button
                                        class="product-filter-mobile filter-toggle"
                                    >
                                        <i class="fas fa-filter"></i>
                                        {{ $t("SZŰRŐ") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End .row -->
                    <div class="row row--15">
                        <ProductRow
                            v-for="(item, index) in items.data"
                            v-bind:key="'product-' + index"
                            :product="item"
                        />
                        <!-- End Single Product  -->
                    </div>
                    <div class="text-center pt--20">
                        <a
                            href="#"
                            v-on:click.prevent="loadMoreData"
                            v-if="showLoadMore"
                            class="axil-btn btn-bg-lighter btn-load-more"
                            >{{ $t("Továbbiak betöltése") }}</a
                        >
                        <!--<div class="overflow-auto">
                            <b-pagination-nav
                                :link-gen="linkGen"
                                :number-of-pages="numberOfPage"
                                :per-page="50"
                                v-model="page"
                                use-router
                            ></b-pagination-nav>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <!-- End .container -->
    </div>
</template>

<script>
import FilterSidebar from "@/components/shop/FilterSidebar.vue";
import ProductRow from "@/components/shop/ProductRow.vue";
import ApiService from "@/core/services/api.service";

export default {
    name: "Index",
    components: { ProductRow, FilterSidebar },
    beforeMount() {
        this.getFilters();

        this.startSearch();
    },
    watch: {
        "$route.query": {
            handler: function(val) {
                this.getFilters();
                this.startSearch(val.page);
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        setSortBy() {
            this.startSearch(1);
        },
        getFilters() {
            const q = JSON.parse(JSON.stringify(this.$route.query));

            if (q.page) {
                this.page = q.page;
            } else {
                this.page = 1;
            }
            if (q.s) {
                this.filter.s = q.s;
            } else this.filter.s = null;
            if (q.set) {
                this.filter.set = q.set;
            } else this.filter.set = "0";

            for (const it in this.rarities) {
                if (
                    q["rarity[" + this.rarities[it] + "]"] !== "undefined" &&
                    q["rarity[" + this.rarities[it] + "]"] !== undefined &&
                    q["rarity[" + this.rarities[it] + "]"] !== "false" &&
                    q["rarity[" + this.rarities[it] + "]"] !== false
                ) {
                    this.filter["rarity[" + this.rarities[it] + "]"] = true;
                } else this.filter["rarity[" + this.rarities[it] + "]"] = false;
            }

            if (q.is_foil) {
                this.filter.is_foil = q.is_foil;
            } else this.filter.is_foil = null;

            if (q.type_line) {
                this.filter.type_line = q.type_line;
            } else this.filter.type_line = null;

            if (q.oracle_text) {
                this.filter.oracle_text = q.oracle_text;
            } else this.filter.oracle_text = null;

            if (q.keywords) {
                this.filter.keywords = q.keywords;
            } else this.filter.keywords = null;

            for (const it in this.colors) {
                if (
                    q["colors[" + this.colors[it] + "]"] !== "undefined" &&
                    q["colors[" + this.colors[it] + "]"] !== undefined &&
                    q["colors[" + this.colors[it] + "]"] !== "false" &&
                    q["colors[" + this.colors[it] + "]"] !== false
                ) {
                    this.filter["colors[" + this.colors[it] + "]"] = true;
                } else this.filter["colors[" + this.colors[it] + "]"] = false;
            }

            for (const it in this.legalities) {
                if (
                    q["legalities[" + this.legalities[it] + "]"] !==
                        "undefined" &&
                    q["legalities[" + this.legalities[it] + "]"] !==
                        undefined &&
                    q["legalities[" + this.legalities[it] + "]"] !== "false" &&
                    q["legalities[" + this.legalities[it] + "]"] !== false
                ) {
                    this.filter[
                        "legalities[" + this.legalities[it] + "]"
                    ] = true;
                } else
                    this.filter[
                        "legalities[" + this.legalities[it] + "]"
                    ] = false;
            }

            if (q.sortBy) {
                this.filter.sortBy = q.sortBy;
            } else this.filter.sortBy = null;

            if (q.sortOrder) {
                this.filter.sortOrder = q.sortOrder;
            } else this.filter.sortOrder = "asc";
        },
        linkGen(pageNum) {
            if (pageNum === 1) {
                return `?${this.encodeQueryData(this.filter)}`;
            } else {
                return `?page=${pageNum}&${this.encodeQueryData(this.filter)}`;
            }
        },
        startSearch: function(page = null, pager = false) {
            const self = this;
            self.$root.$emit("showLoading", true);
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(function() {
                self.isBusy = true;
                let helper = JSON.parse(JSON.stringify(self.filter));
                if (page) {
                    if (self.page !== page) {
                        self.page = page;
                    }
                    helper.page = page;
                } else {
                    helper.page = 1;
                }

                ApiService.query(`card/list/?${self.encodeQueryData(helper)}`)
                    .then(resp => {
                        self.showLoadMore = true;
                        if (!pager) {
                            self.items = resp.data;
                        } else {
                            for (const it in resp.data.data) {
                                self.items.data.push(resp.data.data[it]);
                            }
                        }
                        if (
                            !self.items ||
                            !self.items.data ||
                            self.items.data.length >= self.items.meta.total
                        ) {
                            self.showLoadMore = false;
                        }

                        for (const it in self.items) {
                            self.quantity[self.items[it].id] = 1;
                        }
                        self.numberOfPage = resp.data.meta.last_page;
                        self.isBusy = false;
                        self.baseRarities = resp.data.rarities;
                        self.$forceUpdate();
                    })
                    .finally(() => {
                        self.$root.$emit("hideLoading", true);
                    });
            }, 500);
        },
        loadMoreData() {
            this.startSearch(this.page + 1, true);
        },
        encodeQueryData(data) {
            const ret = [];
            for (const d in data) {
                if (
                    data[d] &&
                    data[d] !== null &&
                    data[d] !== undefined &&
                    data[d] !== ""
                ) {
                    if (d === "rarity" || d === "colors") {
                        for (const it in data[d]) {
                            ret.push(
                                encodeURIComponent(d) +
                                    "[" +
                                    it +
                                    "]" +
                                    "=" +
                                    encodeURIComponent(data[d][it])
                            );
                        }
                    } else {
                        ret.push(
                            encodeURIComponent(d) +
                                "=" +
                                encodeURIComponent(data[d])
                        );
                    }
                }
            }
            return ret.join("&");
        }
    },
    data() {
        return {
            page: 1,
            showLoadMore: true,
            quantity: {},
            numberOfPage: null,
            items: [],
            filter: {
                s: null,
                set: null,
                "rarity[common]": false,
                "rarity[uncommon]": false,
                "rarity[mythic]": false,
                "rarity[special]": false,
                "rarity[rare]": false,
                "rarity[SET]": false,
                "colors[white]": false,
                "colors[blue]": false,
                "colors[black]": false,
                "colors[red]": false,
                "colors[green]": false,
                "legalities[duel]": false,
                "legalities[brawl]": false,
                "legalities[pioneer]": false,
                "legalities[penny]": false,
                "legalities[predh]": false,
                "legalities[future]": false,
                "legalities[legacy]": false,
                "legalities[modern]": false,
                "legalities[pauper]": false,
                "legalities[vintage]": false,
                "legalities[standard]": false,
                "legalities[commander]": false,
                "legalities[oldschool]": false,
                "legalities[premodern]": false,
                "legalities[oathbreaker]": false,
                "legalities[paupercommander]": false,
                is_foil: null,
                sortBy: null,
                sortOrder: "asc",
                type_line: null,
                mana_cost: null,
                cmc: null,
                oracle_text: null,
                keywords: null
            },
            rarities: {
                common: "common",
                uncommon: "uncommon",
                rare: "rare",
                mythic: "mythic",
                "mythic GYÁRI SZÍNHIBÁS": "mythic GYÁRI SZÍNHIBÁS",
                special: "special",
                set: "SET"
            },
            legalities: {
                duel: "duel",
                brawl: "brawl",
                penny: "penny",
                predh: "predh",
                future: "future",
                legacy: "legacy",
                modern: "modern",
                pauper: "pauper",
                pioneer: "pioneer",
                vintage: "vintage",
                standard: "standard",
                commander: "commander",
                oldschool: "oldschool",
                premodern: "premodern",
                oathbreaker: "oathbreaker",
                paupercommander: "paupercommander"
            },
            colors: {
                W: "white",
                U: "blue",
                B: "black",
                R: "red",
                G: "green"
            }
        };
    }
};
</script>

<style scoped></style>
