<template>
    <footer class="axil-footer-area footer-style-2">
        <!-- Start Footer Top Area  -->
        <div class="footer-top separator-top">
            <div class="container">
                <div class="row">
                    <!-- Start Single Widget  -->
                    <div class="col-lg-3 col-sm-6">
                        <div class="axil-footer-widget">
                            <h5 class="widget-title">
                                {{ $t("Személyes átvétel") }}
                            </h5>
                            <!-- <div class="logo mb--30">
                            <a href="index.html">
                                <img class="light-logo" src="assets/images/logo/logo.png" alt="Logo Images">
                            </a>
                        </div> -->
                            <div class="inner">
                                <p>
                                    {{
                                        $t(
                                            "Személyes átvétel esetén a váci Remetebarlangban lesz lehetőséged átvenni nyitva tartási időben. "
                                        )
                                    }}
                                </p>
                                <ul class="support-list-item">
                                    <li>
                                        <a
                                            href="https://tarsasjatekvac.hu"
                                            target="_blank"
                                            ><i class="fal fa-globe-europe"></i>
                                            tarsasjatekvac.hu</a
                                        >
                                    </li>
                                    <!--<li>
                                        <a href="tel:(+01)850-315-5862"
                                            ><i class="fal fa-phone-alt"></i>
                                            (+01) 850-315-5862</a
                                        >
                                    </li>-->
                                    <li>
                                        <i class="fal fa-map-marker-alt"></i>
                                        2600 Vác, <br />
                                        Március 15. tér 16-18 fsz 14.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Widget  -->
                    <!-- Start Single Widget  -->
                    <div class="col-lg-3 col-sm-6">
                        <div class="axil-footer-widget">
                            <h5 class="widget-title">{{ $t("Fiók") }}</h5>
                            <div class="inner">
                                <ul>
                                    <li>
                                        <router-link to="/my-account">{{
                                            $t("Fiókom")
                                        }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/login">{{
                                            $t("Bejelentkezés")
                                        }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/cart">{{
                                            $t("Kosár")
                                        }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/">{{
                                            $t("Bolt")
                                        }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Widget  -->
                    <!-- Start Single Widget  -->
                    <div class="col-lg-3 col-sm-6">
                        <div class="axil-footer-widget">
                            <h5 class="widget-title">{{ $t("Linkek") }}</h5>
                            <div class="inner">
                                <ul>
                                    <li>
                                        <router-link to="/terms">{{
                                            $t(
                                                "Általános Szerződési Feltételek"
                                            )
                                        }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/privacy">{{
                                            $t("Adatvédelmi nyilatkozat")
                                        }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/faq">{{
                                            $t("GYIK")
                                        }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Widget  -->
                    <!-- Start Single Widget  -->
                    <div class="col-lg-3 col-sm-6">
                        <!--<div class="axil-footer-widget">
                            <h5 class="widget-title">Download App</h5>
                            <div class="inner">
                                <span>Save $3 With App & New User only</span>
                                <div class="download-btn-group">
                                    <div class="qr-code">
                                        <img
                                            src="assets/images/others/qr.png"
                                            alt="Axilthemes"
                                        />
                                    </div>
                                    <div class="app-link">
                                        <a href="#">
                                            <img
                                                src="assets/images/others/app-store.png"
                                                alt="App Store"
                                            />
                                        </a>
                                        <a href="#">
                                            <img
                                                src="assets/images/others/play-store.png"
                                                alt="Play Store"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                    <!-- End Single Widget  -->
                </div>
            </div>
        </div>
        <!-- End Footer Top Area  -->
        <!-- Start Copyright Area  -->
        <div class="copyright-area copyright-default separator-top">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-4">
                        <!--<div class="social-share">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-linkedin-in"></i></a>
                            <a href="#"><i class="fab fa-discord"></i></a>
                        </div>-->
                    </div>
                    <div class="col-xl-4 col-lg-12">
                        <div
                            class="copyright-left d-flex flex-wrap justify-content-center"
                        >
                            <ul class="quick-link">
                                <li>
                                    © {{ new Date().getFullYear() }}. All rights
                                    reserved by
                                    <a
                                        target="_blank"
                                        href="https://magic-singles.hu"
                                        >Magic Singles</a
                                    >.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-12">
                        <!--<div
                            class="copyright-right d-flex flex-wrap justify-content-xl-end justify-content-center align-items-center"
                        >
                            <span class="card-text">Accept For</span>
                            <ul class="payment-icons-bottom quick-link">
                                <li>
                                    <img
                                        src="assets/images/icons/cart/cart-1.png"
                                        alt="paypal cart"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="assets/images/icons/cart/cart-2.png"
                                        alt="paypal cart"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="assets/images/icons/cart/cart-5.png"
                                        alt="paypal cart"
                                    />
                                </li>
                            </ul>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <!-- End Copyright Area  -->
    </footer>
</template>

<script>
export default {
    name: "Footer"
};
</script>

<style scoped></style>
