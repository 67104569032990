import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Index from "@/views/Index.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
    /*{
        path: "/regi",
        name: "Home",
        component: Home
    },*/
    {
        path: "/",
        name: "Index",
        component: Index
    },
    {
        path: "/404",
        name: "NotFound",
        component: NotFound
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
        path: "/faq",
        name: "Faq",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "faq" */ "../views/Faq.vue")
    },
    {
        path: "/terms",
        name: "Terms",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "terms" */ "../views/Terms.vue")
    },
    {
        path: "/privacy",
        name: "Privacy",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue")
    },
    {
        path: "/cart",
        name: "Cart",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "cart" */ "../views/CartPage.vue")
    },
    {
        path: "/checkout",
        name: "Checkout",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "checkout" */ "../views/Checkout.vue")
    },
    {
        path: "/success-order",
        name: "SuccessOrder",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "success-order" */ "../views/SuccessOrder.vue"
            )
    },
    {
        path: "/profile",
        name: "Profile",
        meta: {
            auth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
    },
    {
        path: "/registration",
        name: "Registration",
        // route level code-splitting
        // this generates a separate chunk (registration.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "registration" */ "../views/Registration.vue"
            )
    },
    {
        path: "/login",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (registration.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login.vue")
    },
    {
        path: "/my-cards",
        name: "MyCards",
        meta: {
            auth: true
        },
        // route level code-splitting
        // this generates a separate chunk (my-cards.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "my-cards" */ "../views/MyCards.vue")
    },
    {
        path: "/orders",
        name: "Orders",
        meta: {
            auth: true
        },
        // route level code-splitting
        // this generates a separate chunk (orders.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "orders" */ "../views/Orders.vue")
    },
    {
        path: "/forget-password",
        name: "ForgetPass",
        // route level code-splitting
        // this generates a separate chunk (forget-password.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "forget-password" */ "../views/ForgetPass.vue"
            )
    },
    {
        path: "/change_password/:token",
        name: "ResetPass",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "reset-password" */ "../views/ResetPass.vue"
            )
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});

export default router;
