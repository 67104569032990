<template>
    <div class="overlay" v-if="show">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner",
    data() {
        return {
            show: false
        };
    },
    beforeMount() {
        const self = this;

        self.$root.$on("showLoading", function() {
            self.show = true;
        });

        self.$root.$on("hideLoading", function() {
            self.show = false;
        });
    }
};
</script>

<style scoped>
.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #222;
    opacity: 0.8;
    z-index: 10000;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
</style>
