<template>
    <div id="app">
        <LoadingSpinner />
        <BackToTop v-if="diffLayutRoutes" />
        <Header v-if="diffLayutRoutes" />

        <main class="main-wrapper">
            <BreadCrumb
                v-if="diffLayutRoutes"
                :title="breadcrumb.title"
                :breadcrumbs="breadcrumb.breadcrumbs"
            />

            <router-view />
        </main>

        <ServiceArea v-if="diffLayutRoutes" />
        <Footer v-if="diffLayutRoutes" />
        <ProductQuickView v-if="diffLayutRoutes" />
        <HeaderSearchModal v-if="diffLayutRoutes" />
        <CartDropdown v-if="diffLayutRoutes" />
        <!--<div class="badge">Beta</div>-->
        <!--<b-navbar toggleable="lg" type="dark" variant="info">
            <b-navbar-brand tag="h1" class="mb-0">Magic Singles</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item :to="{ name: 'Home' }">{{
                        $t("Kezdőlap")
                    }}</b-nav-item>
                    <b-nav-item :to="{ name: 'About' }" disabled>{{
                        $t("Az oldalról")
                    }}</b-nav-item>
                </b-navbar-nav>
            </b-collapse>

            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown :text="$t('Nyelv')" right>
                    <b-dropdown-item
                        href="#"
                        v-bind:active="currentLanguage === 'hu'"
                        v-on:click.prevent="setLanguage('hu')"
                        >HU</b-dropdown-item
                    >
                    <b-dropdown-item
                        href="#"
                        v-bind:active="currentLanguage === 'en'"
                        v-on:click.prevent="setLanguage('en')"
                        >EN</b-dropdown-item
                    >
                    <b-dropdown-item
                        href="#"
                        v-bind:active="currentLanguage === 'de'"
                        v-on:click.prevent="setLanguage('de')"
                        >DE</b-dropdown-item
                    >
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-navbar>
        <b-container fluid>
            <b-row>
                <b-col>
                    <router-view />
                </b-col>
            </b-row>
        </b-container>
        <a href="#" class="float-left" v-on:click.prevent="toggleLeftMenu">
            <i class="fa-solid fa-cart-shopping my-float"></i>
        </a>
        <a
            href="#"
            class="float d-block d-sm-none"
            v-on:click.prevent="toggleRightMenu"
        >
            <i class="fa-solid fa-magnifying-glass my-float"></i>
        </a>-->
    </div>
</template>

<script>
import store from "@/core/services/store";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";
import { mapGetters, mapState } from "vuex";
import { LOGIN } from "../../admin/src/core/services/store/auth.module";
import BackToTop from "@/components/BackToTop.vue";
import Header from "@/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import ServiceArea from "@/components/ServiceArea.vue";
import Footer from "@/components/Footer.vue";
import ProductQuickView from "@/components/ProductQuickView.vue";
import HeaderSearchModal from "@/components/HeaderSearchModal.vue";
import CartDropdown from "@/components/CartDropdown.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
    name: "App",
    components: {
        LoadingSpinner,
        CartDropdown,
        HeaderSearchModal,
        ProductQuickView,
        Footer,
        ServiceArea,
        BreadCrumb,
        Header,
        BackToTop
    },
    data() {
        return {
            isLoggedIn: false,
            model: {
                email: null,
                password: null
            }
        };
    },
    computed: {
        diffLayutRoutes() {
            if (
                this.$route.name === "Login" ||
                this.$route.name === "Registration" ||
                this.$route.name === "ResetPass" ||
                this.$route.name === "ForgetPass"
            ) {
                return false;
            }

            return true;
        },
        ...mapState({
            errors: state => state.auth.errors
        }),
        ...mapGetters(["currentUser"]),
        currentLanguage() {
            const cl = localStorage.getItem("language");
            if (cl) {
                return cl;
            }
            localStorage.setItem("language", "hu");
            return "hu";
        },
        breadcrumb() {
            if (this.$route.name === "Index") {
                return { title: "Webshop", breadcrumbs: [] };
            }

            if (this.$route.name === "About") {
                return { title: "Rólunk", breadcrumbs: [] };
            }

            if (this.$route.name === "Cart") {
                return { title: "Kosár", breadcrumbs: ["Webshop"] };
            }

            if (this.$route.name === "Faq") {
                return {
                    title: "Gyakran Ismételt Kérdések",
                    breadcrumbs: ["Webshop"]
                };
            }

            if (this.$route.name === "Terms") {
                return {
                    title: "Általános szerződési feltételek",
                    breadcrumbs: ["Webshop"]
                };
            }

            if (this.$route.name === "Profile") {
                return {
                    title: "Profil",
                    breadcrumbs: ["Webshop"]
                };
            }

            if (this.$route.name === "SuccessOrder") {
                return {
                    title: "Sikeres megrendelés",
                    breadcrumbs: ["Webshop"]
                };
            }

            if (this.$route.name === "Privacy") {
                return {
                    title: "Adatvédelmi nyilatkozat",
                    breadcrumbs: ["Webshop"]
                };
            }

            if (this.$route.name === "Checkout") {
                return {
                    title: "Megrendelés",
                    breadcrumbs: ["Webshop", "Kosár"]
                };
            }

            return { title: "", breadcrumbs: [] };
        }
    },
    methods: {
        toggleLeftMenu() {
            this.$eventHub.$emit("toggle-left-menu", true);
            /*$('body').prepend('<div class="bs-canvas-overlay bg-dark position-fixed w-100 h-100"></div>');
            if($(this).hasClass('pull-bs-canvas-right'))
                $('.bs-canvas-right').addClass('mr-0');
            else
                $('.bs-canvas-left').addClass('ml-0');
            return false;*/
        },
        toggleRightMenu() {
            this.$eventHub.$emit("toggle-right-menu", true);
        },
        setLanguage(lang) {
            localStorage.setItem("language", lang);
            document.location.reload();
        },
        onLogout() {
            this.$store.dispatch(LOGOUT).then(() => {
                if (this.$route.name === "Home") {
                    document.location.reload();
                } else {
                    this.$router.push({ name: "Home" });
                }
            });
        },
        doLogin() {
            var email = this.model.email;
            var password = this.model.password;

            if (JwtService.getToken()) {
                this.$store.dispatch(LOGOUT);
            }

            this.$store
                .dispatch(LOGIN, { email, password })
                // go to which page after successfully login
                .then(() => {
                    this.isLoggedIn = true;
                })
                .catch(() => {
                    this.isLoggedIn = false;
                });
        }
    },
    watch: {
        currentUser: {
            handler: function(val) {
                if (val && val.id) {
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            }
        }
    },
    beforeMount() {
        if (this.$route.query.s) {
            this.model.email = this.$route.query.s;
        }
        Promise.all([store.dispatch(VERIFY_AUTH)])
            .then(() => {
                JwtService.saveToken(JwtService.getToken());
            })
            .catch(() => {
                this.isLoggedIn = false;
            });
    }
};
</script>

<style scoped>
.badge {
    position: absolute;
    left: -7px;
    top: 19px;
    background-color: #f70000;
    -webkit-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
    -moz-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
    -o-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
    -ms-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
    transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
    text-align: center;
    color: white;
    padding: 0 0.5em;
    border-top: 0px;
    line-height: 20px;
    margin: 0;
    z-index: 1000;
}
.badge:after {
    content: " ";
    position: absolute;
    right: -19px;
    top: 0px;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #f70000;
}
.badge:before {
    content: " ";
    position: absolute;
    left: -19px;
    top: 0px;
    border-left: 20px solid transparent;
    border-bottom: 20px solid #f70000;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #0c9;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 300;
}

.float-left {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: 40px;
    background-color: #17a2b8;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 300;
}

.my-float {
    margin-top: 22px;
}
</style>
