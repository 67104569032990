import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "@/core/services/store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueCookies from "vue-cookies";
import jQuery from "jquery";

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
// import "toastr/build/toastr.min.css";
import ApiService from "@/core/services/api.service";
import { IS_AUTH } from "@/core/services/store/auth.module";
import i18n from "@/core/plugins/vue-i18n";
import mixins from "./mixins";
import VueGtag from "vue-gtag";

import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(Autocomplete);

Vue.config.productionTip = false;
Vue.prototype.$translate = i18n;
Vue.prototype.$eventHub = new Vue();
Vue.prototype.$ = jQuery;
window.$ = jQuery;

// API service init
ApiService.init();
Vue.mixin(mixins);
Vue.use(
    VueGtag,
    {
        config: { id: "G-W87EN1R854" }
    },
    router
);

import VuePhoneNumberInput from "vue-phone-number-input";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);

ApiService.get(`/system/languages`).then(response => {
    localStorage.setItem("langs", JSON.stringify(response.data));
    for (const item in response.data) {
        ApiService.get(`/translation/${item}`).then(resp => {
            i18n.setLocaleMessage(item, resp.data);
        });
    }
});

router.beforeEach((to, from, next) => {
    // Ensure we checked auth before each page load.
    if (to.meta.auth) {
        Promise.all([store.dispatch(IS_AUTH)])
            .then(resp => {
                if (resp[0]) {
                    next();
                } else {
                    router.push({ name: "Home" });
                }
            })
            .catch(() => {
                router.push({ name: "Home" });
            });
    } else {
        next();
    }

    // Scroll page to top on every route change
    setTimeout(() => {
        // window.scrollTo(0, 0);
    }, 100);
});

new Vue({
    router,
    store,
    i18n,
    VueCookies,
    render: h => h(App)
}).$mount("#app");
