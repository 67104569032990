<template>
    <div class="axil-breadcrumb-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-8">
                    <div class="inner">
                        <ul class="axil-breadcrumb">
                            <li class="axil-breadcrumb-item">
                                <router-link to="/">{{
                                    $t("Kezdőlap")
                                }}</router-link>
                            </li>
                            <template v-for="(item, index) in breadcrumbs">
                                <li
                                    v-bind:key="'bc-' + index"
                                    class="separator"
                                ></li>
                                <li
                                    v-bind:key="'li-' + index"
                                    class="axil-breadcrumb-item active"
                                    aria-current="page"
                                >
                                    {{ $t(item) }}
                                </li>
                            </template>
                        </ul>
                        <h1 class="title">{{ $t(title) }}</h1>
                    </div>
                </div>
                <div class="col-lg-6 col-md-4">
                    <!--<div class="inner">
                        <div class="bradcrumb-thumb">
                            <img
                                src="assets/images/product/product-45.png"
                                alt="Image"
                            />
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BreadCrumb",
    props: ["title", "breadcrumbs"]
};
</script>

<style scoped></style>
