<template>
    <div class="cart-dropdown" id="cart-dropdown">
        <div class="cart-content-wrap">
            <div class="cart-header">
                <h2 class="header-title">{{ $t("Kosár") }}</h2>
                <button class="cart-close sidebar-close">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="cart-body">
                <p>
                    {{
                        $t("A feltüntetett darabszámok ritkán, de eltérhetnek!")
                    }}
                </p>
                <ul class="cart-item-list">
                    <li
                        class="cart-item"
                        v-for="(item, index) in cart"
                        v-bind:key="'cart-item-' + index"
                    >
                        <div class="item-img">
                            <a href="javascript:void(0);"
                                ><img
                                    v-if="
                                        !item.image_uris ||
                                            !item.image_uris.normal
                                    "
                                    src="/assets/images/product/electric/product-01.png"
                                    alt="Product Image"
                                />
                                <img
                                    v-else
                                    :src="item.image_uris.normal"
                                    :alt="item.name"
                                />
                            </a>
                            <button
                                class="close-btn"
                                v-on:click="deleteCartItem(index)"
                            >
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                        <div class="item-content">
                            <div class="product-rating">
                                <span class="rating-number">
                                    {{ item.set.toUpperCase() }} /
                                    {{ item.collector_number }}</span
                                >
                            </div>
                            <h3 class="item-title">
                                <a href="javascript:void(0);"
                                    >{{ item.name }}
                                    <i v-if="item.is_foil" class="fa fa-star" />
                                </a>
                            </h3>
                            <div class="item-price">
                                {{ item.price_formatted }}
                            </div>
                            <div class="pro-qty item-quantity">
                                <input
                                    type="number"
                                    class="quantity-input"
                                    readonly
                                    :value="item.quantity"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="cart-footer">
                <h3 class="cart-subtotal">
                    <span class="subtotal-title">{{ $t("Összesen") }}:</span>
                    <span class="subtotal-amount">{{ totalPayment }} Ft</span>
                </h3>
                <div class="group-btn">
                    <router-link
                        to="/cart"
                        class="axil-btn btn-bg-primary viewcart-btn"
                        >{{ $t("Kosár megtekintése") }}</router-link
                    >
                    <router-link
                        to="/checkout"
                        class="axil-btn btn-bg-secondary checkout-btn"
                        >{{ $t("Megrendelés") }}</router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "CartDropdown",
    beforeMount() {
        const self = this;
        const cart = this.$cookies.get("cart");

        ApiService.post(`/cart/update`, { cart: cart, no_popup: true }).then(
            resp => {
                self.cart = resp.data;
                self.isUpdating = false;
            }
        );

        self.$root.$on("updateCart", function() {
            const cart = self.$cookies.get("cart");

            ApiService.post(`/cart/update`, {
                cart: cart,
                no_popup: true
            }).then(resp => {
                self.cart = resp.data;
                self.isUpdating = false;
            });
        });
    },
    data() {
        return {
            cart: {}
        };
    },
    computed: {
        totalPayment() {
            let total = 0;
            for (const it in this.cart) {
                total +=
                    parseInt(this.cart[it].price) *
                    parseInt(this.cart[it].quantity);
            }

            return total;
        }
    },
    methods: {
        deleteCartItem(index) {
            const self = this;
            self.cart.splice(index, 1);
            self.isUpdating = true;

            ApiService.post(`/cart/update`, { cart: self.cart }).then(resp => {
                self.cart = resp.data;
                let cHelp = {};
                for (const it in self.cart) {
                    cHelp["c" + self.cart[it].sell_id] = self.cart[it].quantity;
                }

                self.$cookies.set("cart", JSON.stringify(cHelp), 60 * 60 * 12);
                self.isUpdating = false;
                self.$root.$emit("updateCart", true);
            });

            this.$gtag.event("remove_from_cart", {
                event_category: "ecommerce",
                event_label: "remove_from_cart",
                value: index
            });
        }
    }
};
</script>

<style scoped></style>
