<template>
    <a href="#top" class="back-to-top" id="backto-top"
        ><i class="fal fa-arrow-up"></i
    ></a>
</template>

<script>
export default {
    name: "BackToTop"
};
</script>

<style scoped></style>
