<template>
    <div class="col-xl-4 col-sm-6">
        <div class="axil-product product-style-one mb--30">
            <div class="thumbnail">
                <a href="javascript:void(0);">
                    <img
                        v-if="!product.image_uris || !product.image_uris.normal"
                        src="/assets/images/product/electric/product-01.png"
                        alt="Product Images"
                    />
                    <img v-else :src="cardFace" :alt="product.name" />
                </a>
                <div class="label-block label-right">
                    <div
                        class="product-badget"
                        v-bind:class="{ 'foil-class': product.is_foil }"
                    >
                        {{ product.set.toUpperCase() }} /
                        {{ product.collector_number }}
                        <template v-if="product.is_foil">
                            <br />
                            FOIL
                        </template>
                    </div>
                </div>
                <div class="product-hover-action">
                    <ul class="cart-action">
                        <li class="whislist">
                            <input
                                style="border: 1px solid #ccc; line-height: 40px"
                                type="number"
                                value="1"
                                v-model="quantity"
                                :max="product.quantity"
                                min="1"
                            />
                        </li>
                        <li class="select-option">
                            <a
                                v-if="
                                    product.sellable >= 1 &&
                                        product.price_standard > 0
                                "
                                href="#"
                                v-on:click.prevent="
                                    addToCart(
                                        product.id,
                                        product.name,
                                        product.is_foil
                                    )
                                "
                                ><i class="far fa-cart-plus"
                            /></a>
                            <a disabled="disabled" v-else>
                                {{ $t("Nem rendelhető") }}
                            </a>
                        </li>
                        <li class="quickview">
                            <a v-on:click="openModal(product)"
                                ><i class="far fa-eye"></i
                            ></a>
                        </li>
                        <li
                            class="quickview"
                            v-if="
                                product.image_uris &&
                                    product.image_uris.normal &&
                                    (product.name.includes(' // ') ||
                                        product.type_line === 'Card // Card')
                            "
                        >
                            <a v-on:click.prevent="frontFace = !frontFace">
                                <i class="fa fa-circle-notch"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="product-content">
                <div class="inner">
                    <h5 class="title">
                        <a href="javascript:void(0);"
                            >{{ product.name }}
                            <template v-if="product.is_foil">
                                - FOIL
                            </template></a
                        >
                        <span class="avaibility">
                            {{ $t("Elérhető:") }}
                            {{ product.quantity }} {{ $t("db") }}
                        </span>
                    </h5>
                    <div class="product-price-variant">
                        <span class="price current-price">{{
                            product.price
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "ProductRow",
    props: ["product"],
    data() {
        return {
            quantity: 1,
            frontFace: 1
        };
    },
    beforeMount() {
        this.quantity = 1;
    },
    watch: {
        $route: {
            handler: function() {
                this.quantity = 1;
            },
            deep: true
        }
    },
    computed: {
        cardFace: function() {
            if (this.frontFace) {
                return this.product.image_uris.normal;
            }

            return this.product.image_uris.normal.replace("/front", "/back");
        }
    },
    methods: {
        openModal(product) {
            this.$gtag.event("view_item", {
                event_category: "engagement",
                event_label: "view_item",
                value: product.name
            });

            localStorage.setItem("selectedProduct", JSON.stringify(product));
            this.$root.$emit("bv::show::modal", "quick-view-modal", "#btnShow");
        },
        addToCart(id) {
            const self = this;
            self.isUpdating = true;
            let cart = {};
            if (self.$cookies.get("cart")) {
                cart = self.$cookies.get("cart");
            }

            let qty = this.quantity;
            if (!qty || isNaN(qty)) qty = 1;

            if (cart["c" + id]) {
                cart["c" + id] += parseInt(qty);
            } else {
                cart["c" + id] = parseInt(qty);
            }

            this.$gtag.event("add_to_cart", {
                event_category: "ecommerce",
                event_label: "add_to_cart",
                value: id
            });

            ApiService.post(`/cart/check`, { cart: cart }).then(() => {
                ApiService.post(`/cart/update`, { cart: cart }).then(resp => {
                    self.cart = resp.data;
                    let cHelp = {};
                    for (const it in self.cart) {
                        cHelp["c" + self.cart[it].sell_id] =
                            self.cart[it].quantity;
                    }

                    self.$cookies.set(
                        "cart",
                        JSON.stringify(cHelp),
                        60 * 60 * 12
                    );
                    self.isUpdating = false;
                    self.$root.$emit("updateCart", true);
                });
            });
        }
    }
};
</script>

<style scoped>
.foil-class {
    background-color: red !important;
}
.avaibility {
    display: block;
    font-size: 13px;
}

.axil-product > .thumbnail .label-block {
    top: 50px !important;
}
</style>
