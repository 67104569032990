import store from "@/core/services/store";
import { SET_MODEL } from "@/core/services/store/defaultStore";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
    computed: {
        ...mapGetters(["getErrors"]),

        getSvgImage() {
            return imagePath => {
                return process.env.BASE_URL + imagePath;
            };
        }
    },
    methods: {
        setMetaTags(data) {
            document.title =
                data.meta_title + " - " + process.env.VUE_APP_APP_NAME;

            if (document.getElementsByName("robots").length > 0) {
                document.getElementsByName("robots")[0].remove();
            }

            this.createOrReplaceMeta(
                "og:title",
                data.meta_title + " - " + process.env.MIX_APP_NAME
            );
            this.createOrReplaceMeta("description", data.meta_description);
            if (data.og_image) {
                this.createOrReplaceMeta("og:image", data.og_image);
            } else {
                this.createOrReplaceMeta("og:image", "/frontend/img/logo.svg");
            }
            this.createOrReplaceMeta("og:url", document.location.href);
        },

        historyGetByRoute(path) {
            let history = localStorage.getItem("urlHistoryList");
            if (!history || !history.length) {
                return path;
            }
            history = JSON.parse(history);
            if (!history[path]) {
                return path;
            }
            return history[path];
        },
        createOrReplaceMeta(it, data) {
            if (document.getElementsByName(it).length < 1) {
                const tag = document.createElement("meta");
                tag.setAttribute("name", it);
                tag.setAttribute("content", data);
                tag.setAttribute("data-vue-router-controlled", "");

                document.head.appendChild(tag);
            } else {
                document.getElementsByName(it)[0].setAttribute("content", data);
                document
                    .getElementsByName(it)[0]
                    .setAttribute("data-vue-router-controlled", "");
            }
        },
        hasError(val) {
            const errors = this.getErrors;
            for (const er in errors) {
                if (er === val) {
                    return true;
                }
            }

            return false;
        },
        filterError: function(name) {
            const errors = this.getErrors;
            for (const er in errors) {
                if (er === name) {
                    return errors[er];
                }
            }
            return "";
        },
        save: function(type) {
            this.$eventHub.$emit("save", type);
        },
        sanitizeTitle: function(title) {
            var slug = "";
            try {
                // Change to lower case
                var titleLower = title.toLowerCase();
                // Letter "í"
                slug = titleLower.replace(/í/gi, "i");
                // Letter "e"
                slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
                // Letter "a"
                slug = slug.replace(
                    /a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi,
                    "a"
                );
                // Letter "o"
                slug = slug.replace(
                    /o|ó|ò|õ|ỏ|ọ|ő|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi,
                    "o"
                );
                // Letter "u"
                slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ü|ű|ứ|ừ|ữ|ử|ự/gi, "u");
                // Letter "d"
                slug = slug.replace(/đ/gi, "d");
                // Trim the last whitespace
                slug = slug.replace(/\s*$/g, "");
                // Change whitespace to "-"
                slug = slug.replace(/\s+/g, "-");
            } catch (e) {
                return slug;
            }

            return slug;
        },
        encodeQueryData(data) {
            const ret = [];
            for (const d in data) {
                if (
                    data[d] &&
                    data[d] !== null &&
                    data[d] !== undefined &&
                    data[d] !== ""
                ) {
                    ret.push(
                        encodeURIComponent(d) +
                            "=" +
                            encodeURIComponent(data[d])
                    );
                }
            }
            return ret.join("&");
        },
        toastMessage(_text1, _text2, _success = true) {
            window.toastr.options = {
                closeButton: true,
                debug: false,
                newestOnTop: false,
                progressBar: false,
                positionClass: "toast-bottom-right",
                preventDuplicates: true,
                onclick: null,
                showDuration: "30000",
                hideDuration: "100000",
                timeOut: "500000",
                extendedTimeOut: "100000",
                showEasing: "swing",
                hideEasing: "linear",
                showMethod: "fadeIn",
                hideMethod: "fadeOut"
            };

            if (_success) {
                window.toastr.success(_text1, _text2);
            } else {
                window.toastr.error(_text1, _text2);
            }
        },
        handleCreate(_module, _url, _defaultModel = {}) {
            this.handleSave(_module, _url, _defaultModel, "create");
        },
        handleUpdate(_module, _url, _defaultModel = {}) {
            this.handleSave(_module, _url, _defaultModel, "update");
        },
        handleSave(_module, _url, _defaultModel = {}, _actionType) {
            const self = this;

            let url = _url + "/" + _actionType;

            if (_actionType === "update" && this.$route.params.id) {
                url += "/" + this.$route.params.id;
            }
            this.$eventHub.$off("save");
            this.$eventHub.$on("save", function(type) {
                let sendType = "post";
                if (_actionType === "update") {
                    sendType = "put";
                }

                const sendModel = JSON.parse(
                    JSON.stringify(self.$store.state.defaultStore.model)
                );
                sendModel.selectables = [];

                ApiService[sendType](url, sendModel).then(response => {
                    switch (type) {
                        case 1:
                            if (self.$route.path.includes("/create")) {
                                self.$router.push(
                                    "/admin/" +
                                        _module +
                                        "/edit/" +
                                        response.data.data.id
                                );
                            }
                            break;
                        case 2:
                            store.dispatch(SET_MODEL, _defaultModel);
                            self.$router.push("/admin/" + _module + "/create");
                            break;
                        case 3:
                            self.$router.push("/admin/" + _module + "/index");
                            break;
                    }
                    self.$eventHub.$emit("modelSaved", response.data);
                });
            });
        }
    }
};
