var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"quick-view-modal",staticClass:"modal fade quick-view-product",attrs:{"dialog-class":"quick-view-product","modal-class":"quick-view-product","id":"quick-view-modal","tabindex":"-1","aria-hidden":"true","hide-footer":"","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('b-button',{staticClass:"btn-close",on:{"click":function($event){return close()}}},[_vm._v(" X ")])]}}])},[(
            _vm.product &&
                Object.keys(_vm.product).length > 0 &&
                _vm.productDetails &&
                Object.keys(_vm.productDetails).length > 0
        )?_c('div',{staticClass:"single-product-thumb"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-7 mb--40"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 order-lg-2"},[_c('div',{staticClass:"single-product-thumbnail product-large-thumbnail axil-product thumbnail-badge zoom-gallery"},[_c('div',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":_vm.selectedImage,"alt":_vm.product.name}})])])]),_c('div',{staticClass:"col-lg-2 order-lg-1"},[_c('div',{staticClass:"product-small-thumb small-thumb-wrapper"},[_c('div',{staticClass:"small-thumb-img front"},[_c('img',{attrs:{"src":_vm.product.image_uris.normal,"alt":_vm.product.name},on:{"click":function($event){_vm.selectedImage =
                                            _vm.product.image_uris.normal}}})]),(
                                    _vm.product.name.includes(' // ') ||
                                        _vm.product.type_line === 'Card // Card'
                                )?_c('div',{staticClass:"small-thumb-img back"},[_c('img',{attrs:{"src":_vm.product.image_uris.normal.replace(
                                            'front',
                                            'back'
                                        ),"alt":_vm.product.name},on:{"click":function($event){_vm.selectedImage = _vm.product.image_uris.normal.replace(
                                            'front',
                                            'back'
                                        )}}})]):_vm._e()])])])]),_c('div',{staticClass:"col-lg-5 mb--40"},[_c('div',{staticClass:"single-product-content"},[_c('div',{staticClass:"inner"},[_c('h3',{staticClass:"product-title"},[_vm._v(" "+_vm._s(_vm.product.name)+" "),(_vm.product.is_foil)?[_vm._v(" - FOIL")]:_vm._e()],2),_c('span',{staticClass:"price-amount"},[_vm._v(_vm._s(_vm.product.price)+" "),_c('hr'),_vm._v(" "+_vm._s(_vm.product.quantity)+" db készleten")]),_c('ul',{staticClass:"product-meta"},[_c('li',[_vm._v(_vm._s(_vm.productDetails.set_name))]),_c('li',[_vm._v(" "+_vm._s(_vm.productDetails.set.toUpperCase())+" / "+_vm._s(_vm.productDetails.collector_number)+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.productDetails.rarity.toUpperCase())+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.productDetails.type_line)+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.productDetails.mana_cost)+" ")])]),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.productDetails.oracle_text)}}),_c('div',{staticClass:"product-variations-wrapper"},[_c('div',{staticClass:"product-variation"},[_c('h6',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("Legalitás"))+": ")]),_c('ul',{staticClass:"range-variant"},[_vm._l((_vm.productDetails.legalities),function(item,index){return [(item === 'legal')?_c('li',{key:'legal-' +
                                                    item +
                                                    '-' +
                                                    index},[_vm._v(" "+_vm._s(index)+" ")]):_vm._e()]})],2)])]),(
                                _vm.product.sellable >= 1 &&
                                    _vm.product.price_standard > 0
                            )?_c('div',{staticClass:"product-action-wrapper d-flex-center"},[_c('div',{staticClass:"pro-qty"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quantity),expression:"quantity"}],staticStyle:{"border":"1px solid #ccc"},attrs:{"type":"text","value":"1","max":_vm.product.quantity,"min":"1"},domProps:{"value":(_vm.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.quantity=$event.target.value}}})]),_c('ul',{staticClass:"product-action d-flex-center mb--0"},[_c('li',{staticClass:"add-to-cart"},[_c('a',{staticClass:"axil-btn btn-bg-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addToCart(_vm.product.id)}}},[_vm._v(_vm._s(_vm.$t("Kosárhoz adás")))])])])]):_c('div',{staticClass:"product-action-wrapper d-flex-center"},[_vm._v(" "+_vm._s(_vm.$t("A termék nem rendelhető"))+" ")])])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }