<template>
    <form v-on:submit.prevent="startFilter">
        <div class="axil-shop-sidebar">
            <div class="d-lg-none">
                <button class="sidebar-close filter-close-btn">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div
                class="toggle-list product-categories"
                v-bind:class="{ active: filterToggle.s }"
            >
                <h6 class="title" v-on:click="filterToggle.s = !filterToggle.s">
                    {{ $t("Kártya neve") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.s }"
                >
                    <div class="form-group">
                        <label for="s"></label>
                        <input
                            type="text"
                            name="s"
                            id="s"
                            v-model="filter.s"
                            autocomplete="off"
                        />
                    </div>
                </div>
            </div>
            <div
                class="toggle-list product-categories"
                v-bind:class="{ active: filterToggle.set }"
            >
                <h6
                    class="title"
                    v-on:click="filterToggle.set = !filterToggle.set"
                >
                    {{ $t("Kiadás") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.set }"
                >
                    <div class="form-group" v-if="sets && sets.length > 0">
                        <label for="set"></label>
                        <autocomplete
                            :search="search"
                            :placeholder="$t('Kiadás')"
                            :aria-label="$t('Kiadás')"
                            :get-result-value="getResultValue"
                            @submit="handleSubmit"
                            :default-value="getDefaultSetValue"
                        ></autocomplete>
                    </div>
                </div>
            </div>
            <div
                class="toggle-list product-categories"
                v-bind:class="{ active: filterToggle.rarity }"
            >
                <h6
                    class="title"
                    v-on:click="filterToggle.rarity = !filterToggle.rarity"
                >
                    {{ $t("Ritkaság") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.rarity }"
                >
                    <ul>
                        <li
                            v-bind:class="{ 'current-cat': checkRarity(item) }"
                            v-for="(item, index) in rarities"
                            v-bind:key="'rarity-' + index"
                        >
                            <a href="#" v-on:click.prevent="setRarity(item)">{{
                                item
                            }}</a>
                            <div
                                class="form-group input-group"
                                style="display: none"
                            >
                                <input
                                    type="checkbox"
                                    :id="'rarity-' + item"
                                    name="rarity"
                                    :value="item"
                                />
                                <label :for="'rarity-' + item">{{
                                    item
                                }}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div
                class="toggle-list product-categories"
                v-bind:class="{ active: filterToggle.legalities }"
            >
                <h6
                    class="title"
                    v-on:click="
                        filterToggle.legalities = !filterToggle.legalities
                    "
                >
                    {{ $t("Legalitás") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.legalities }"
                >
                    <ul>
                        <li
                            v-bind:class="{
                                'current-cat': checkLegality(item)
                            }"
                            v-for="(item, index) in legalities"
                            v-bind:key="'rarity-' + index"
                        >
                            <a
                                href="#"
                                v-on:click.prevent="setLegality(item)"
                                >{{ item }}</a
                            >
                            <div
                                class="form-group input-group"
                                style="display: none"
                            >
                                <input
                                    type="checkbox"
                                    :id="'rarity-' + item"
                                    name="rarity"
                                    :value="item"
                                />
                                <label :for="'rarity-' + item">{{
                                    item
                                }}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div
                class="toggle-list product-categories "
                v-bind:class="{ active: filterToggle.is_foil }"
            >
                <h6
                    class="title"
                    v-on:click="filterToggle.is_foil = !filterToggle.is_foil"
                >
                    {{ $t("Foil") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.is_foil }"
                >
                    <div class="form-group">
                        <label for="is_foil"></label>
                        <select
                            name="is_foil"
                            id="is_foil"
                            v-model="filter.is_foil"
                        >
                            <option
                                v-for="(item, index) in foils"
                                v-bind:key="'is_foil-' + index"
                                :value="index"
                                >{{ item }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div
                class="toggle-list product-categories "
                v-bind:class="{ active: filterToggle.cmc }"
            >
                <h6
                    class="title"
                    v-on:click="filterToggle.cmc = !filterToggle.cmc"
                >
                    {{ $t("Converted Mana Cost (CMC)") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.cmc }"
                >
                    <div class="form-group">
                        <label for="cmc"></label>
                        <input
                            type="text"
                            name="cmc"
                            id="cmc"
                            v-model="filter.cmc"
                        />
                    </div>
                </div>
            </div>

            <div
                class="toggle-list product-categories "
                v-bind:class="{ active: filterToggle.type_line }"
            >
                <h6
                    class="title"
                    v-on:click="
                        filterToggle.type_line = !filterToggle.type_line
                    "
                >
                    {{ $t("Type Line") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.type_line }"
                >
                    <div class="form-group">
                        <label for="type_line"></label>
                        <input
                            type="text"
                            name="type_line"
                            id="type_line"
                            autocomplete="off"
                            v-model="filter.type_line"
                        />
                    </div>
                </div>
            </div>

            <div
                class="toggle-list product-categories "
                v-bind:class="{ active: filterToggle.oracle_text }"
            >
                <h6
                    class="title"
                    v-on:click="
                        filterToggle.oracle_text = !filterToggle.oracle_text
                    "
                >
                    {{ $t("Oracle Text") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.oracle_text }"
                >
                    <div class="form-group">
                        <label for="oracle_text"></label>
                        <input
                            type="text"
                            name="oracle_text"
                            id="oracle_text"
                            v-model="filter.oracle_text"
                            autocomplete="off"
                        />
                    </div>
                </div>
            </div>

            <div
                class="toggle-list product-categories "
                v-bind:class="{ active: filterToggle.keywords }"
            >
                <h6
                    class="title"
                    v-on:click="filterToggle.keywords = !filterToggle.keywords"
                >
                    {{ $t("Keywords") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.keywords }"
                >
                    <div class="form-group">
                        <label for="keywords"></label>
                        <input
                            type="text"
                            name="keywords"
                            id="keywords"
                            v-model="filter.keywords"
                            autocomplete="off"
                        />
                    </div>
                </div>
            </div>

            <div
                class="toggle-list product-categories"
                v-bind:class="{ active: filterToggle.colors }"
            >
                <h6
                    class="title"
                    v-on:click="filterToggle.colors = !filterToggle.colors"
                >
                    {{ $t("Szín") }}
                </h6>
                <div
                    class="shop-submenu"
                    v-bind:class="{ 'hidden-area': !filterToggle.colors }"
                >
                    <ul>
                        <li
                            v-for="(item, index) in colors"
                            v-bind:key="'colors-' + index"
                            v-bind:class="{ 'current-cat': checkColors(item) }"
                        >
                            <a href="#" v-on:click.prevent="setColors(item)">{{
                                item
                            }}</a>
                            <div
                                class="form-group input-group"
                                style="display: none"
                            >
                                <input
                                    type="checkbox"
                                    :id="'colors-' + item"
                                    name="colors"
                                    :value="item"
                                />
                                <label :for="'colors-' + item">{{
                                    item
                                }}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <button class="axil-btn btn-bg-primary" type="submit">
                {{ $t("Szűrés") }}
            </button>
            <hr />
            <button
                class="axil-btn btn-bg-secondary"
                type="button"
                v-on:click="resetFilter"
            >
                {{ $t("Alaphelyzetbe") }}
            </button>
        </div>
    </form>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "FilterSidebar",
    props: ["filter", "rarities", "legalities", "colors"],
    data() {
        return {
            filterToggle: {
                s: true,
                set: null,
                rarity: false,
                is_foil: false,
                cmc: false,
                type_line: false,
                oracle_text: false,
                colors: false,
                keywords: false,
                legalities: false
            },
            sets: [],
            foils: {
                null: "Összes",
                "0": "Csak nem foil",
                "1": "Csak foil"
            }
        };
    },
    computed: {
        getDefaultSetValue() {
            if (this.$route.query.set) {
                const setName = this.sets.find(
                    t =>
                        t.set.toUpperCase() ===
                        this.$route.query.set.toUpperCase()
                );

                if (setName) {
                    return setName.set_name;
                }
            }

            return "";
        }
    },
    methods: {
        handleSubmit(result) {
            this.filter.set = result.set;
        },
        search(input) {
            return this.sets.filter(
                t =>
                    t.set.toUpperCase().includes(input.toUpperCase()) ||
                    t.set_name.toUpperCase().includes(input.toUpperCase())
            );
        },
        getResultValue(result) {
            return result.set_name;
        },
        checkColors(colors) {
            if (this.filter["colors[" + colors + "]"]) {
                this.filterToggle.colors = true;
                return true;
            }
            return false;
        },
        setColors(colors) {
            this.filter["colors[" + colors + "]"] = !this.filter[
                "colors[" + colors + "]"
            ];
        },
        checkRarity(rarity) {
            if (this.filter["rarity[" + rarity + "]"]) {
                this.filterToggle.rarity = true;
                return true;
            }
            return false;
        },
        setRarity(rarity) {
            this.filter["rarity[" + rarity + "]"] = !this.filter[
                "rarity[" + rarity + "]"
            ];
        },
        checkLegality(legalities) {
            if (this.filter["legalities[" + legalities + "]"]) {
                this.filterToggle.legalities = true;
                return true;
            }
            return false;
        },
        setLegality(legalities) {
            this.filter["legalities[" + legalities + "]"] = !this.filter[
                "legalities[" + legalities + "]"
            ];
        },
        getSets: function() {
            ApiService.query(`card/sets`).then(resp => {
                this.sets = resp.data;
            });
        },
        startFilter() {
            let q = {
                s: this.filter.s,
                set: this.filter.set,
                is_foil: this.filter.is_foil,
                cmc: this.filter.cmc,
                type_line: this.filter.type_line,
                "rarity[common]": false,
                "rarity[uncommon]": false,
                "rarity[mythic]": false,
                "rarity[special]": false,
                "rarity[rare]": false,
                "rarity[SET]": false,
                "rarity[mythic GYÁRI SZÍNHIBÁS]": false,
                "colors[white]": false,
                "colors[blue]": false,
                "colors[black]": false,
                "colors[red]": false,
                "colors[green]": false,
                "legalities[duel]": false,
                "legalities[brawl]": false,
                "legalities[pioneer]": false,
                "legalities[penny]": false,
                "legalities[predh]": false,
                "legalities[future]": false,
                "legalities[legacy]": false,
                "legalities[modern]": false,
                "legalities[pauper]": false,
                "legalities[vintage]": false,
                "legalities[standard]": false,
                "legalities[commander]": false,
                "legalities[oldschool]": false,
                "legalities[premodern]": false,
                "legalities[oathbreaker]": false,
                "legalities[paupercommander]": false,
                oracle_text: this.filter.oracle_text,
                keywords: this.filter.keywords
            };

            for (const it in this.rarities) {
                if (this.filter["rarity[" + this.rarities[it] + "]"])
                    q["rarity[" + this.rarities[it] + "]"] = true;
            }

            for (const it in this.colors) {
                if (this.filter["colors[" + this.colors[it] + "]"])
                    q["colors[" + this.colors[it] + "]"] = true;
            }

            for (const it in this.legalities) {
                if (this.filter["legalities[" + this.legalities[it] + "]"])
                    q["legalities[" + this.legalities[it] + "]"] = true;
            }

            this.$gtag.event("view_search_results", {
                event_category: "engagement",
                event_label: "view_search_results",
                value: JSON.stringify(q)
            });

            // eslint-disable-next-line no-undef
            if (jQuery(".axil-shop-sidebar").hasClass("open")) {
                // eslint-disable-next-line no-undef
                jQuery(".filter-toggle").click();
            }

            this.$router.replace({
                name: "Index",
                query: q
            });
        },
        resetFilter() {
            // eslint-disable-next-line no-undef
            if (jQuery(".axil-shop-sidebar").hasClass("open")) {
                // eslint-disable-next-line no-undef
                jQuery(".filter-toggle").click();
            }
            this.$router.push({ name: "Index" });
        }
    },
    beforeMount() {
        this.getSets();

        for (const it in this.filterToggle) {
            if (this.$route.query[it]) {
                this.filterToggle[it] = true;
            }
        }
    }
};
</script>

<style>
.hidden-area {
    display: none !important;
}

.autocomplete-input {
    border: 1px solid #eee !important;
    border-radius: 8px !important;
    width: 100% !important;
    padding: 12px 12px 12px 48px !important;
    box-sizing: border-box !important;
    position: relative !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    flex: 1 !important;
    background-color: #eee !important;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+") !important;
    background-repeat: no-repeat !important;
    background-position: 12px !important;
}

.autocomplete-input:focus,
.autocomplete-input[aria-expanded="true"] {
    border-color: rgba(0, 0, 0, 0.12) !important;
    background-color: #fff !important;
    outline: none !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16) !important;
}

[data-position="below"] .autocomplete-input[aria-expanded="true"] {
    border-bottom-color: transparent !important;
    border-radius: 8px 8px 0 0 !important;
}

[data-position="above"] .autocomplete-input[aria-expanded="true"] {
    border-top-color: transparent !important;
    border-radius: 0 0 8px 8px !important;
    z-index: 2 !important;
}

.autocomplete[data-loading="true"]:after {
    content: "" !important;
    border: 3px solid rgba(0, 0, 0, 0.12) !important;
    border-right-color: rgba(0, 0, 0, 0.48) !important;
    border-radius: 100% !important;
    width: 20px !important;
    height: 20px !important;
    position: absolute !important;
    right: 12px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    animation: rotate 1s linear infinite !important;
}

.autocomplete-result-list {
    margin: 0 !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    max-height: 296px !important;
    overflow-y: auto !important;
    background: #fff !important;
    list-style: none !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16) !important;
}

[data-position="below"] .autocomplete-result-list {
    margin-top: -1px !important;
    border-top-color: transparent !important;
    border-radius: 0 0 8px 8px !important;
    padding-bottom: 8px !important;
}

[data-position="above"] .autocomplete-result-list {
    margin-bottom: -1px !important;
    border-bottom-color: transparent !important;
    border-radius: 8px 8px 0 0 !important;
    padding-top: 8px !important;
}

.autocomplete-result {
    cursor: default !important;
    padding: 12px 12px 12px 48px !important;
    background-image: url("data:image/svg+xml !important;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjY2NjIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+") !important;
    background-repeat: no-repeat !important;
    background-position: 12px !important;
}

.autocomplete-result:hover,
.autocomplete-result[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0.06) !important;
}

@keyframes rotate {
    0% {
        transform: translateY(-50%) rotate(0deg) !important;
    }
    to {
        transform: translateY(-50%) rotate(359deg) !important;
    }
}
</style>
