<template>
    <header class="header axil-header header-style-5">
        <div class="axil-header-top">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-sm-6 col-12">
                        <div class="header-top-dropdown">
                            <div class="dropdown">
                                <button
                                    class="dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {{ $t(currentLanguage) }}
                                </button>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item
                                        href="#"
                                        v-bind:active="currentLanguage === 'hu'"
                                        v-on:click.prevent="setLanguage('hu')"
                                        >{{ $t("magyar") }}</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        href="#"
                                        v-bind:active="currentLanguage === 'en'"
                                        v-on:click.prevent="setLanguage('en')"
                                        >{{ $t("english") }}</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        href="#"
                                        v-bind:active="currentLanguage === 'de'"
                                        v-on:click.prevent="setLanguage('de')"
                                        >{{ $t("deutsch") }}</b-dropdown-item
                                    >
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-12">
                        <div class="header-top-link" v-if="!user || !user.id">
                            <ul class="quick-link">
                                <li>
                                    <router-link to="/registration">{{
                                        $t("Regisztráció")
                                    }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/login">{{
                                        $t("Bejelentkezés")
                                    }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Start Mainmenu Area  -->
        <div id="axil-sticky-placeholder"></div>
        <div class="axil-mainmenu">
            <div class="container">
                <div class="header-navbar">
                    <div class="header-brand">
                        <router-link to="/" class="logo logo-dark">
                            <img
                                src="/logo.png"
                                alt="Site Logo"
                                style="max-width: 150px"
                            />
                        </router-link>
                    </div>
                    <div class="header-main-nav">
                        <!-- Start Mainmanu Nav -->
                        <nav class="mainmenu-nav">
                            <button class="mobile-close-btn mobile-nav-toggler">
                                <i class="fas fa-times"></i>
                            </button>
                            <div class="mobile-nav-brand">
                                <router-link to="/" class="logo">
                                    <img src="/logo.png" alt="Site Logo" />
                                </router-link>
                            </div>
                            <ul class="mainmenu">
                                <li>
                                    <router-link to="/">{{
                                        $t("Kezdőlap")
                                    }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/about">{{
                                        $t("Az oldalról")
                                    }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/faq">{{
                                        $t("Gyakran Ismételt Kérdések")
                                    }}</router-link>
                                </li>
                            </ul>
                        </nav>
                        <!-- End Mainmanu Nav -->
                    </div>
                    <div class="header-action">
                        <ul class="action-list">
                            <li
                                class="axil-search d-xl-block d-none"
                                v-on:click="openModal"
                            >
                                <input
                                    type="search"
                                    class="placeholder product-search-input"
                                    name="search2"
                                    id="search2"
                                    value=""
                                    maxlength="128"
                                    :placeholder="$t('Decklist kereső')"
                                    autocomplete="off"
                                />
                                <button
                                    type="submit"
                                    class="icon wooc-btn-search"
                                >
                                    <i class="flaticon-magnifying-glass"></i>
                                </button>
                            </li>
                            <li class="axil-search d-xl-none d-block">
                                <a
                                    href="javascript:void(0)"
                                    class="header-search-icon"
                                    title="Search"
                                >
                                    <i class="flaticon-magnifying-glass"></i>
                                </a>
                            </li>
                            <li class="shopping-cart">
                                <a href="#" class="cart-dropdown-btn">
                                    <span
                                        class="cart-count"
                                        v-if="cartCount > 0"
                                        >{{ cartCount }}</span
                                    >
                                    <i class="flaticon-shopping-cart"></i>
                                </a>
                            </li>
                            <li class="my-account">
                                <a
                                    href="javascript:void(0)"
                                    v-on:click.prevent="
                                        quickLinkOpen = !quickLinkOpen
                                    "
                                >
                                    <i class="flaticon-person"></i>
                                </a>
                                <div
                                    class="my-account-dropdown"
                                    v-bind:class="{ open: quickLinkOpen }"
                                >
                                    <span class="title">{{
                                        $t("GYORSLINKEK")
                                    }}</span>
                                    <ul>
                                        <template v-if="user && user.id">
                                            <li>
                                                <router-link to="/profile">{{
                                                    $t("Fiókom")
                                                }}</router-link>
                                            </li>
                                            <!--<li>
                                                <router-link
                                                    to="/profile#nav-orders"
                                                    >{{
                                                        $t("Rendeléseim")
                                                    }}</router-link
                                                >
                                            </li>-->
                                            <li>
                                                <a
                                                    href="#"
                                                    v-on:click.prevent="logout"
                                                    >{{
                                                        $t("Kijelentkezés")
                                                    }}</a
                                                >
                                            </li>
                                        </template>
                                    </ul>
                                    <template v-if="!user || !user.id">
                                        <router-link
                                            to="/login"
                                            class="axil-btn btn-bg-primary"
                                            >{{ $t("Belépés") }}</router-link
                                        >
                                        <div class="reg-footer text-center">
                                            {{ $t("Nincs még fiókod?") }}
                                            <router-link
                                                to="/registration"
                                                class="btn-link"
                                                >{{
                                                    $t("Regisztráció")
                                                }}</router-link
                                            >
                                        </div>
                                    </template>
                                </div>
                            </li>
                            <li class="axil-mobile-toggle">
                                <button class="menu-btn mobile-nav-toggler">
                                    <i class="flaticon-menu-2"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Mainmenu Area -->
        <div class="header-top-campaign" v-if="alertShow">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-5 col-lg-6 col-md-10">
                        <div
                            class="header-campaign-activation axil-slick-arrow arrow-both-side header-campaign-arrow"
                        >
                            <div class="slick-slides">
                                <div class="campaign-content">
                                    <p v-html="notification"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
    name: "Header",
    computed: {
        ...mapState({
            errors: state => state.auth.errors
        }),
        ...mapGetters(["currentUser"]),
        currentLanguage() {
            const cl = localStorage.getItem("language");
            if (cl) {
                switch (cl) {
                    case "hu":
                        return "magyar";
                    case "en":
                        return "english";
                    case "de":
                        return "deutsch";
                    default:
                        return "magyar";
                }
            }
            return "magyar";
        }
    },
    methods: {
        logout() {
            this.$cookies.remove("user");
            this.$cookies.remove("id_token");
            document.location.href = "/";
        },
        getUserData() {
            if (this.$cookies.get("id_token")) {
                ApiService.get(`/user/profile`)
                    .then(response => {
                        this.user = response.data.data;
                        this.$cookies.set("user", response.data.data, 86400);
                    })
                    .catch(() => {
                        this.$cookies.remove("user");
                        this.$cookies.remove("id_token");
                    });
            }
        },
        openModal() {
            this.$root.$emit(
                "bv::show::modal",
                "header-search-modal",
                "#btnShow"
            );
        },
        setLanguage(lang) {
            localStorage.setItem("language", lang);
            document.location.reload();
        },
        getNotification() {
            ApiService.query(`/system/notification`).then(resp => {
                this.notification = resp.data.notification;
                if (this.notification) {
                    this.alertShow = true;
                }
            });
        }
    },
    data() {
        return {
            orderSent: false,
            notification: null,
            alertShow: false,
            cartCount: 0,
            user: {},
            quickLinkOpen: false
        };
    },
    watch: {
        "$route.query": {
            handler: function() {
                this.quickLinkOpen = false;
            },
            deep: true
        }
    },
    beforeMount() {
        const self = this;
        self.getNotification();
        self.getUserData();

        const cart = self.$cookies.get("cart");
        if (cart) {
            self.cartCount = Object.keys(cart).length;
        }

        self.$root.$on("updateCart", function() {
            const cart = self.$cookies.get("cart");

            if (cart) {
                self.cartCount = Object.keys(cart).length;
            }
        });
    }
};
</script>

<style scoped></style>
