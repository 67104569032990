<template>
    <div class="service-area">
        <div class="container">
            <div class="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon">
                            <img
                                src="/assets/images/icons/service1.png"
                                alt="Service"
                            />
                        </div>
                        <div class="content">
                            <h6 class="title">
                                {{ $t("Gyors és Biztonságos szállítás") }}
                            </h6>
                            <p>
                                {{ $t("Foxpost automatába vagy otthonodba.") }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon">
                            <img
                                src="/assets/images/icons/service2.png"
                                alt="Service"
                            />
                        </div>
                        <div class="content">
                            <h6 class="title">{{ $t("Garantált minőség") }}</h6>
                            <p>
                                {{ $t("Kártyáink garantáltan NM minőségűek.") }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon">
                            <img
                                src="/assets/images/icons/service3.png"
                                alt="Service"
                            />
                        </div>
                        <div class="content">
                            <h6 class="title">
                                {{ $t("Csomagolási minőség") }}
                            </h6>
                            <p>
                                {{
                                    $t(
                                        "A rendelt kártyáidat a lehető legbiztosabban csomagoljuk be, hogy szállítás során se sérüljön meg."
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon">
                            <img
                                src="/assets/images/icons/service4.png"
                                alt="Service"
                            />
                        </div>
                        <div class="content">
                            <h6 class="title">
                                {{ $t("Maximális odafigyelés") }}
                            </h6>
                            <p>
                                {{
                                    $t(
                                        "Rendelésedet a legnagyobb gondosággal és odafigyeléssel készítjük elő."
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServiceArea"
};
</script>

<style scoped></style>
