<template>
    <b-modal
        class="modal fade quick-view-product"
        dialog-class="quick-view-product"
        modal-class="quick-view-product"
        id="quick-view-modal"
        ref="quick-view-modal"
        tabindex="-1"
        aria-hidden="true"
        hide-footer
        centered
    >
        <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <b-button class="btn-close" @click="close()">
                X
            </b-button>
        </template>
        <div
            class="single-product-thumb"
            v-if="
                product &&
                    Object.keys(product).length > 0 &&
                    productDetails &&
                    Object.keys(productDetails).length > 0
            "
        >
            <div class="row">
                <div class="col-lg-7 mb--40">
                    <div class="row">
                        <div class="col-lg-10 order-lg-2">
                            <div
                                class="single-product-thumbnail product-large-thumbnail axil-product thumbnail-badge zoom-gallery"
                            >
                                <div class="thumbnail">
                                    <img
                                        :src="selectedImage"
                                        :alt="product.name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 order-lg-1">
                            <div
                                class="product-small-thumb small-thumb-wrapper"
                            >
                                <div class="small-thumb-img front">
                                    <img
                                        :src="product.image_uris.normal"
                                        :alt="product.name"
                                        v-on:click="
                                            selectedImage =
                                                product.image_uris.normal
                                        "
                                    />
                                </div>
                                <div
                                    class="small-thumb-img back"
                                    v-if="
                                        product.name.includes(' // ') ||
                                            product.type_line === 'Card // Card'
                                    "
                                >
                                    <img
                                        :src="
                                            product.image_uris.normal.replace(
                                                'front',
                                                'back'
                                            )
                                        "
                                        :alt="product.name"
                                        v-on:click="
                                            selectedImage = product.image_uris.normal.replace(
                                                'front',
                                                'back'
                                            )
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 mb--40">
                    <div class="single-product-content">
                        <div class="inner">
                            <h3 class="product-title">
                                {{ product.name }}
                                <template v-if="product.is_foil">
                                    - FOIL</template
                                >
                            </h3>
                            <span class="price-amount"
                                >{{ product.price }}
                                <hr />
                                {{ product.quantity }} db készleten</span
                            >
                            <ul class="product-meta">
                                <li>{{ productDetails.set_name }}</li>
                                <li>
                                    {{ productDetails.set.toUpperCase() }} /
                                    {{ productDetails.collector_number }}
                                </li>
                                <li>
                                    {{ productDetails.rarity.toUpperCase() }}
                                </li>
                                <li>
                                    {{ productDetails.type_line }}
                                </li>
                                <li>
                                    {{ productDetails.mana_cost }}
                                </li>
                            </ul>
                            <p
                                class="description"
                                v-html="productDetails.oracle_text"
                            ></p>

                            <div class="product-variations-wrapper">
                                <!-- End Product Variation  -->

                                <!-- Start Product Variation  -->
                                <div class="product-variation">
                                    <h6 class="title">
                                        {{ $t("Legalitás") }}:
                                    </h6>
                                    <ul class="range-variant">
                                        <template
                                            v-for="(item,
                                            index) in productDetails.legalities"
                                        >
                                            <li
                                                v-bind:key="
                                                    'legal-' +
                                                        item +
                                                        '-' +
                                                        index
                                                "
                                                v-if="item === 'legal'"
                                            >
                                                {{ index }}
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                                <!-- End Product Variation  -->
                            </div>

                            <!-- Start Product Action Wrapper  -->
                            <div
                                class="product-action-wrapper d-flex-center"
                                v-if="
                                    product.sellable >= 1 &&
                                        product.price_standard > 0
                                "
                            >
                                <!-- Start Quentity Action  -->
                                <div class="pro-qty">
                                    <input
                                        style="border: 1px solid #ccc"
                                        type="text"
                                        value="1"
                                        v-model="quantity"
                                        :max="product.quantity"
                                        min="1"
                                    />
                                </div>
                                <!-- End Quentity Action  -->

                                <!-- Start Product Action  -->
                                <ul class="product-action d-flex-center mb--0">
                                    <li class="add-to-cart">
                                        <a
                                            href="#"
                                            v-on:click.prevent="
                                                addToCart(product.id)
                                            "
                                            class="axil-btn btn-bg-primary"
                                            >{{ $t("Kosárhoz adás") }}</a
                                        >
                                    </li>
                                </ul>
                                <!-- End Product Action  -->
                            </div>
                            <div
                                class="product-action-wrapper d-flex-center"
                                v-else
                            >
                                {{ $t("A termék nem rendelhető") }}
                            </div>
                            <!-- End Product Action Wrapper  -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "ProductQuickView",
    data() {
        return {
            quantity: 1,
            product: {},
            productDetails: {},
            selectedImage: null
        };
    },
    beforeMount() {
        const self = this;
        self.$root.$on("bv::modal::show", (bvEvent, modalId) => {
            if (modalId === "quick-view-modal") {
                self.product = JSON.parse(
                    localStorage.getItem("selectedProduct")
                );

                if (
                    self.product.image_uris &&
                    Object.keys(self.product.image_uris).length > 0
                ) {
                    self.selectedImage = self.product.image_uris.normal;
                }

                ApiService.get("/card/card-data/" + self.product.id).then(
                    response => {
                        self.productDetails = response.data.data;
                    }
                );
            }
        });
    },
    methods: {
        addToCart(id) {
            this.isUpdating = true;
            let cart = {};
            if (this.$cookies.get("cart")) {
                cart = this.$cookies.get("cart");
            }

            let qty = this.quantity;
            if (!qty || isNaN(qty)) qty = 1;

            if (cart["c" + id]) {
                cart["c" + id] += parseInt(qty);
            } else {
                cart["c" + id] = parseInt(qty);
            }

            this.$gtag.event("add_to_cart", {
                event_category: "ecommerce",
                event_label: "add_to_cart",
                value: id
            });

            ApiService.post(`/cart/check`, { cart: cart }).then(() => {
                ApiService.post(`/cart/update`, { cart: cart }).then(resp => {
                    this.cart = resp.data;
                    let cHelp = {};
                    for (const it in this.cart) {
                        cHelp["c" + this.cart[it].sell_id] = this.cart[
                            it
                        ].quantity;
                    }

                    this.$cookies.set(
                        "cart",
                        JSON.stringify(cHelp),
                        60 * 60 * 12
                    );
                    this.isUpdating = false;
                    this.$root.$emit("updateCart", true);
                });
            });
        }
    }
};
</script>

<style scoped></style>
