<template>
    <section class="error-page onepage-screen-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="content">
                        <span class="title-highlighter highlighter-secondary">
                            <i class="fal fa-exclamation-circle"></i>
                            {{ $t("Hoppá! Valami hiba történt!") }}</span
                        >
                        <h1 class="title">
                            {{ $t("Az oldal nem található!") }}
                        </h1>
                        <p>
                            {{
                                $t(
                                    "Úgy tűnik, hogy a keresett oldal nem létezik vagy nem tudott megfelelően betölteni."
                                )
                            }}
                        </p>
                        <router-link
                            to="/"
                            class="axil-btn btn-bg-secondary right-icon"
                            >{{ $t("Vissza a kezdőlapra") }}
                            <i class="fal fa-long-arrow-right"></i
                        ></router-link>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="thumbnail">
                        <img src="/assets/images/others/404.png" alt="404" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "NotFound"
};
</script>

<style scoped></style>
